import styled from 'styled-components';

import { MainContainerPanel } from './PanelLayots';

interface IProps {
  isOpenSlide?: boolean;
  fromHistory?: boolean;
  isHistory?: boolean;
  littenChatPreviewId?: string | null;
}
const MainContainerTable = styled.div`
  width: ${({ isOpenSlide, littenChatPreviewId }: IProps) => {
    if (littenChatPreviewId) {
      return '100%';
    }
    return isOpenSlide ? '73.5%' : '95%';
  }};
  //margin:${({ isOpenSlide }: IProps) => (isOpenSlide ? '0 0 0 5%' : 'auto')};
  transition: width 400ms;
  @media (max-width: 767px) {
    width: ${({ isOpenSlide }: IProps) => (isOpenSlide ? '100%' : '90%')};
    margin: auto;
  }
`;

const MasterDetailContainer = styled.div`
  position: relative;
  ${MainContainerPanel} {
    width: 100%;
  }
  height: 100%;
  .SlideOpened {
    width: 25%;
    min-width: 320px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
`;

const MasterDetailLeftAside = styled.div`
  min-width: 320px;
  width: 25%;
  height: 100%;
  // width: ${({ isOpenSlide }: IProps) => (isOpenSlide ? '37.5%' : '25%')};
  //transition: width 300ms 200ms;
  @media (max-width: 767px) {
    display: ${({ fromHistory }: IProps) => (fromHistory ? 'block' : 'none')};
    width: 100% !important;
    height: ${({ isHistory }: IProps) => (isHistory ? 'auto' : '100%')};
  }
`;

const MasterDetailMain = styled.div`
  height: 100%;
  width: ${({ isOpenSlide }: IProps) => (isOpenSlide ? '50%' : '75%')};
  transition: width 300ms;
  p {
    margin: 20px 16px;
  }
  @media (max-width: 767px) {
    width: 100% !important;
    display: ${({ fromHistory }: IProps) => (fromHistory ? 'none' : 'block')};
  }
`;

const MasterDetailOutsideContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 767px) {
    flex-wrap: ${({ isHistory }: IProps) => (isHistory ? 'wrap' : 'inherit')};
    overflow: ${({ isHistory }: IProps) => (isHistory ? 'auto' : 'inherit')};
    #chatContainer {
      width: 100%;
      max-width: 100%;
      transition: all 1s;
      display: block;
    }
    #chatContainer.chatView {
      position: absolute;
      left: -9999px;
    }
    #liveContainer {
      position: absolute;
      right: -9999px;
      transition: all 0.5s;
      width: 100%;
    }
    #liveContainer.chatView {
      position: relative;
      right: 0;
      display: block;
    }
  }
`;

export {
  MainContainerTable,
  MasterDetailContainer,
  MasterDetailOutsideContainer,
  MasterDetailLeftAside,
  MasterDetailMain,
};
