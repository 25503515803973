/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { saveSlide, closeSlide } from '../../Store/actions/slide';
import { channelIcons } from '../../utils/states';
import { BtnShowChat, LeadChannel } from '../AllChats/allChats.style';
import Colors from '../../utils/colors';
import { translate } from '../../services/translate';
import 'react-datepicker/dist/react-datepicker.css';
import getConversationHistory from '../../services/history';
import capitalize from '../../utils/capitalize';
import { Convs, StateAllChats } from '../../Interfaces/AllChats';
import { FORMATS_DATE_TIME } from '../../Constants';
import { Chat } from '../../Interfaces/History';

import { Tag, AlignedDate } from './styles';
import HistoryComponent from './History.component';

const colorsTags = {
  lead: Colors.successColorDarker,
  'no lead': Colors.grayIcon,
};

declare global {
  interface Window {
    location: Location;
  }
}

const History = () => {
  const history = useHistory();
  const slidePanel = useSelector((state: StateAllChats) => state.slidePanel);
  const users = useSelector((state: StateAllChats) => state.users);
  const lang = useSelector((state: StateAllChats) => state.getLanguage);
  const dispatch = useDispatch();
  const [selectedChat] = useState<string>('');
  const [hideChats] = useState(true);
  const me = useSelector((state: StateAllChats) => state.auth.user);

  const usersFilter = users.filter((user) => {
    let aprov = me.permissions.includes('admin') || user.allowedWebsiteIds?.length === 0;
    if (!aprov) {
      me.allowedWebsiteIds.forEach((id: string) => {
        aprov = aprov || user.allowedWebsiteIds?.includes(id);
      });
    }
    return aprov;
  });

  const [data, setData] = useState<Chat[]>([]);
  const [paginationConfig, setPagination] = useState<{
     page: number, page_count: number,
     page_size: number, total_count: number;}>();
  const account = useSelector((state: StateAllChats) => state.auth?.account);
  const sendUserToLive = () => {
    history.push('/live');
  };
  const hasHistory = account.features.available.LIVE_CONVERSATION_HISTORY
    || account.labs.history_conversations;

  // if (!hasHistory) sendUserToLive();

  moment.defineLocale(lang, {
    relativeTime: {
      s: `< 1 ${translate('minute')}`,
      ss: `< 1 ${translate('minute')}`,
    },
  });

  const getData = async (
    page?: { page: number },
    query?: string,
  ): Promise<void> => {
    const { paginationSettings, data } = await getConversationHistory(
      page,
      query,
    );
    setData(data || []);
    setPagination(paginationSettings);
  };

  const localizeFormatter = (lang: 'en' | 'es' | 'pt') => {
    const formatWithLang = FORMATS_DATE_TIME[lang] ?? FORMATS_DATE_TIME.es;
    return (date: string) => moment(date).format(formatWithLang);
  };

  const getTime = (chatTime: string) => {
    const last24Hrs = moment().subtract(24, 'hours').format();
    const lastConvTime = moment(chatTime).format();
    if (lastConvTime >= last24Hrs) return moment(chatTime).fromNow();
    const formatter = localizeFormatter(lang);
    return formatter(chatTime);
  };

  const itemsForTable = (): Convs[] => {
    if (!data) {
      return [];
    }
    return data.map((e: Chat) => {
      const lead = e.is_lead ? 'lead' : 'no lead';
      const oprator = usersFilter.find((u) => u.id === e.operator.id)?.name;
      const intervened = e.was_intervened && oprator ? capitalize(oprator) : '-';
      const visitor = e.visitor.name
        ? capitalize(e.visitor.name)
        : translate('client');
      return {
        id: e.id,
        webId: e.website.id,
        sessionStatus: e.status,
        table: [
          {
            label: (
              <span className="capitalize">
                <strong>{visitor}</strong>
              </span>
            ),
            key: 'name',
            value: e.visitor.name,
          },
          {
            label: <AlignedDate>{getTime(e.last_contact_date)}</AlignedDate>,
            key: 'lastContact',
            value: e.last_contact_date,
          },
          {
            label: <Tag Color={colorsTags[lead]}>{lead.toUpperCase()}</Tag>,
            key: 'condition',
            value: lead,
          },
          {
            label: <span className="capitalize">{intervened}</span>,
            key: 'intervened',
            value: intervened,
          },
          {
            label: (
              <LeadChannel>
                <i className={`icon-${channelIcons[e.channel]}`} />
                {translate(`channels.${e.channel}`)}
              </LeadChannel>
            ),
            key: 'channel',
            value: e.channel,
          },
          {
            label: (
              <BtnShowChat>
                {translate('visitors_section.general_table.view_chat')}
              </BtnShowChat>
            ),
            value: 'ver chat',
            key: 'buttonAction',
          },
        ],
      };
    });
  };

  useEffect(() => {
    getData().then((response) => response).catch((error: string) => error);
    if (slidePanel.open) dispatch(closeSlide());
    dispatch(saveSlide({ open: false, content: selectedChat }));
  }, []);

  return (
    <HistoryComponent
      setPage={getData}
      hideChats={hideChats}
      convs={itemsForTable()}
      selectedChat={selectedChat}
      paginationConfig={paginationConfig}
    />
  );
};

export default History;
