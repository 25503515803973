import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SearchSelect, Checkbox, TextInput } from 'cliengo-ui';
import moment from 'moment';

import { CHANNELS, CONDITIONS } from '../../Constants';
import Accordion from '../Accordion/Accordion';
import TagLabel from '../TagLabel/TagLabel';
import './filter.css';
import { Colors } from '../../utils';
import { translate } from '../../services/translate';
import { channelIcons } from '../../utils/states';
import analytics, { EVENTS } from '../../services/analytics';
import { WebsiteFilter } from "../WebsiteFilter/WebsiteFilter.component";

import CustomDateTimePicker from './CustomDateTimePicker';

const FilterHistory = ({
  data: { usersFilter, sitesStore },
  setIsFiltered,
  setQuery,
  getDataFilter,
  setIsOpenFilter,
}) => {
  const [formFilter, setformFilter] = useState({
    website: '',
    from: '',
    to: '',
    visitor: '',
    operator: {},
    channel: {},
    condition: {},
  });
  const [isOpen, setIsOpen] = useState({ 0: false, 1: false, 2: false });
  const account = useSelector((state) => state.auth?.account);

  let accordions = [
    {
      title: translate('history_section.operatorFilterTitle'),
      data: usersFilter,
      key: 'operator',
    },
    {
      title: translate('history_section.channelFilterTitle'),
      data: CHANNELS,
      key: 'channel',
    },
    {
      title: translate('history_section.conditionFilterTitle'),
      data: CONDITIONS,
      key: 'condition',
    },
  ];

  if (account.labs.ecommerce) {
    accordions = accordions.filter((item) => item.key !== 'condition');
    delete formFilter.condition;
  }

  const accordionsKeys = accordions.map((item) => item.key);

  const trackChangeName = () => {
    const event = {
      name: EVENTS.CHAT_SEARCH_HISTORY,
      category: 'live',
    };
    analytics.trackEvent(event);
  };

  const trackWebsiteSelection = (value) => {
    const site = sitesStore.find((site) => site.value === value);
    const event = {
      name: EVENTS.CHAT_SEARCH_HISTORY,
      category: 'live',
      label: site.label,
    };
    analytics.trackEvent(event);
  };

  const changeWebsiteSelection = (value) => {
    trackWebsiteSelection(value);
    onChangeValue(value, 'website');
  };

  const trackOperatorSelection = () => {
    const event = {
      name: EVENTS.USER_FILTER,
      category: 'live',
    };
    analytics.trackEvent(event);
  };

  const trackChannelSelection = (channel) => {
    const event = {
      name: EVENTS.CANAL_FILTER,
      category: 'live',
      label: channel.id,
    };
    analytics.trackEvent(event);
  };

  const trackConditionSelection = (condition) => {
    const event = {
      name: EVENTS.STATUS_FILTER,
      category: 'live',
      label: condition.id,
    };
    analytics.trackEvent(event);
  };

  const onChangeValue = (value, key) => {
    if (accordionsKeys.includes(key)) {
      if (Object.keys(formFilter[key]).includes(value.id)) {
        deleteFilter(value.id, key);
      } else {
        setformFilter({ ...formFilter, [key]: { ...formFilter[key], [value.id]: value } });
        const trackFunctions = {
          channel: trackChannelSelection,
          condition: trackConditionSelection,
          operator: trackOperatorSelection,
          website: trackWebsiteSelection,
        };
        if (trackFunctions[key]) trackFunctions[key](value);
      }
    } else {
      setformFilter({ ...formFilter, [key]: value.toString() });
    }
  };
  const deleteFilter = (value, key) => {
    if (key) {
      const valuesFilter = formFilter[key];
      delete valuesFilter[value];
      setformFilter({ ...formFilter, [key]: valuesFilter });
    } else {
      setformFilter({ ...formFilter, [value]: '' });
    }
  };

  const trackPressApplyFilter = () => {
    const event = {
      name: EVENTS.APPLY_FILTER,
      category: 'live',
    };
    analytics.trackEvent(event);
  };
  const clickAction = () => {
    let query = '?';
    const operator = [];
    const channel = [];
    const condition = [];

    if (formFilter.website === 'all') formFilter.website = '';
    if (formFilter.operator) {
      Object.keys(formFilter.operator).map((user) => operator.push(formFilter.operator[user].id));
    }
    if (formFilter.channel) Object.keys(formFilter.channel).map((user) => channel.push(formFilter.channel[user].label));
    if (formFilter.condition) {
      Object.keys(formFilter.condition).map((user) => condition.push(formFilter.condition[user].label));
    }
    if (formFilter.website) query = query + (query === '?' ? 'website=' : '&website=') + formFilter.website;
    if (formFilter.visitor) query += (query === '?' ? 'visitor=' : '&visitor=') + formFilter.visitor;
    if (formFilter.from) query += (query === '?' ? 'from=' : '&from=') + moment.utc(formFilter.from).toISOString();
    if (formFilter.to) query += (query === '?' ? 'to=' : '&to=') + moment.utc(formFilter.to).toISOString();
    if (operator.length > 0) query += (query === '?' ? 'operator=' : '&operator=') + operator.toString();
    if (channel.length > 0) query += (query === '?' ? 'channel=' : '&channel=') + channel.toString();
    if (condition.length > 0) query += (query === '?' ? 'condition=' : '&condition=') + condition.toString();

    trackPressApplyFilter();
    setIsFiltered(true);
    setQuery(query);
    getDataFilter(query);
  };

  useEffect(() => {
  }, [formFilter]);

  const minDate = new Date(moment().subtract(90, 'days'));
  const getTranslation = (key) => (translate(`history_section.${key.toLowerCase()}`)
    ? translate(`history_section.${key.toLowerCase()}`) : key);
  return (
    <div className="wrapper-history">
      <h1 className="title-panel">{translate('history_section.chat_history')}</h1>
      <div style={{ width: '100%', padding: 15, boxSizing: 'border-box' }}>
        <TextInput
          id="search-data"
          className="mb-2 outline-none"
          value={formFilter.visitor}
          callback={(val) => onChangeValue(val, 'visitor')}
          onBlur={() => trackChangeName()}
          placeholderText={translate('history_section.form.searchVisitor')}
          style={{ borderColor: Colors.cliengoMain }}
        />

        <WebsiteFilter websites={sitesStore} onChange={(value) => changeWebsiteSelection(value)} />

        <div className="row history calendar-shadow" style={{ marginBottom: 20, boxSizing: 'border-box', zIndex: 4 }}>
          <CustomDateTimePicker
            label={translate('history_section.form.from')}
            onValue={formFilter.from}
            onChangeValue={(value) => onChangeValue(value, 'from')}
            minDate={minDate}
            maxDate={new Date()}
          />
        </div>
        <div className="row history calendar-shadow mb-2" style={{ marginBottom: 20, boxSizing: 'border-box' }}>
          <CustomDateTimePicker
            label={translate('history_section.form.to')}
            onValue={formFilter.to}
            onChangeValue={(value) => onChangeValue(value, 'to')}
            minDate={minDate}
            maxDate={new Date()}
          />
        </div>
        <div className="filter-selected row is-flex-wrap-wrap">
          {Object.keys(formFilter).some((item) => formFilter[item].length > 0
            || Object.keys(formFilter[item]).length > 0)
            && <p style={{ color: '#7D60F5' }}>{translate('history_section.form.cleanFilters')}</p>}

          {Object.keys(formFilter).map((key, index) => {
            if (formFilter[key] === '' || formFilter[key].length <= 0) return false;
            let newValue = ['to', 'from'].includes(key) ? moment(formFilter[key]).format('DD-MM-YYYY hh:mm A') : formFilter[key];
            if (key === 'website') {
              newValue = sitesStore.find((site) => site.value === formFilter[key])?.label;
            }
            if (accordionsKeys.includes(key)) {
              return Object.keys(formFilter[key]).map((item) => (
                <TagLabel
                  label={formFilter[key][item].label}
                  callBack={() => deleteFilter(formFilter[key][item].id, key)}
                />
              ));
            }
            return <TagLabel label={newValue} key={index} callBack={() => deleteFilter(key)} />;
          })}
        </div>
        {accordions.map((accordion, index) => (
          <Accordion key={index} title={accordion.title} isOpen={isOpen[index]} callback={() => setIsOpen({ ...isOpen, [index]: !isOpen[index] })}>
            {accordion && accordion.data && accordion.data.map((item, index) => (
              <div className="row" key={`row${index}`} style={{ margin: 15 }}>
                {accordion.key === 'channel' ? (
                  <div className="is-flex is-align-items-center justify-content-center channel">
                    <Checkbox
                      label=""
                      id={`${accordion.key}-${item.id}`}
                      value={formFilter[accordion.key][item.id]?.id}
                      callback={() => onChangeValue({ id: item.id, label: item.name }, accordion.key)}
                    />
                    <i className={`icon-${item.icon}`} />
                    <p className="m-0">{getTranslation(item.name)}</p>
                  </div>
                )
                  : (
                    <Checkbox
                      label={getTranslation(item.name)}
                      id={`${accordion.key}-${item.id}`}
                      value={formFilter[accordion.key][item.id]?.id}
                      callback={() => onChangeValue({ id: item.id, label: item.name }, accordion.key)}
                    />
                  )}
              </div>
            ))}
          </Accordion>
        ))}
        <div className="row justify-content">
          <button className="buttonFilter" type="button" onClick={() => clickAction()}>
            {' '}
            {translate('history_section.form.applyFilters')}
            {' '}
          </button>
        </div>
        <div className="is-flex is-full-width
          is-justify-content-center p-1 box-sizing-border-box container-icon-collapse-filter"
        >
          <i className="icon-expand-less" onClick={() => setIsOpenFilter(false)} />
        </div>
      </div>
    </div>
  );
};

export default FilterHistory;
