/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';

import { fetchWebsitesAndUsers } from '../Store/Authentication/Thunks';
import Page404 from '../Components/Errors/Page404';
import Splash from '../Components/Loaders/MainLoader';
import '../normalize.css';
import SocketeerConnection from '../services/socketeerConnections';
import GeneralError from '../Components/Errors/GeneralError/GeneralError';
import { updateChatInfo, getLang } from '../Store/actions';
import config from '../services/config';
import { initTranslation } from '../services/translate';
import { removePreLoader } from '../utils/init';
import AllChats from '../Pages/AllChats/index';
import History from '../Pages/History';
import './styles.css';
import { Socket, StateAllChats } from '../Interfaces/AllChats';
import { RootState } from '../Interfaces/Default';
import postMessageToLitten from '../utils/postMessageToLitten';
import useIFrameMessages from '../hooks/useIFrameMessages';

interface Props {
  updateChatInfo: () => void;
}

declare global {
  interface Window {
    location: Location;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const IFrameApp = ({ updateChatInfo }: Props) => {
  useIFrameMessages();

  const chatInfo = useSelector((state: StateAllChats) => state.chatInfo);
  const auth = useSelector((state: RootState) => state.auth);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const [initialized, setInitialized] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [socketConnection, setSocketConnection] = useState<Socket>({
    sendMessage: () => 1,
    sendAction: () => 1,
  });
  const [error] = useState<boolean>(false);
  const dispatch = useDispatch();
  const initialMethod = () => {
    const {
      urls: { CRM },
    } = config;
    if (auth && auth.account) {
      if (auth.account.hideLeads) window.location.href = `${CRM}/billing`;
      const urlParams = new URLSearchParams(window.location.search);
      const jwt = urlParams.get('jwt');
      setSocketConnection(
        SocketeerConnection(chatInfo, dispatch, updateChatInfo, jwt, {
          account: auth.account,
          user: auth.user,
        }),
      );

      // analytics.identify(auth.account, auth.user);
      initTranslation(auth.user.language);
      dispatch(getLang(auth.user.language));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !initialized) {
      dispatch(fetchWebsitesAndUsers());
      initialMethod();
      removePreLoader();
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    postMessageToLitten('appLoaded');
  }, []);

  // eslint-disable-next-line react/jsx-filename-extension
  if (error) return <GeneralError />;
  if (loading) return <Splash />;

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/live/visitors" />
      </Route>
      <Route path="/live/visitors" exact>
        <Redirect to="/live/visitors/available" />
      </Route>
      <Route path="/live/history" exact>
        <History />
      </Route>
      <Route exact path="/live/visitors/:tab">
        <AllChats socket={socketConnection} />
      </Route>
      <Route component={Page404} />
    </Switch>
  );
};

export default connect(null, {
  updateChatInfo,
})(IFrameApp);
