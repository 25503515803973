export default {
  contact: 'Contacto',
  unknown: 'Desconocido',
  client: 'Cliente',
  minute: 'minuto',
  of: 'de',
  return_crm: 'Volver a la plataforma',
  notifications_menu: 'Notificaciones',
  configure_bot: 'Configurar Bot',
  visitors_section: {
    visitors: 'Visitantes',
    without_operators: 'Sin Operador',
    chatting: 'Otros operadores ',
    all: 'Todos',
    all_sites: 'Todos los sitios',
    select_a_site: 'Seleccionar sitio',
    select_a_tag: 'Seleccionar etiquetas',
    tagIsPremium: 'Puedes filtrar tus conversaciones a partir de un plan Premium',
    search_chat: 'Buscar chat',
    online: 'En línea',
    offline: 'Ausente',
    no_chats_found: 'No hay resultados para esta búsqueda',
    no_chats_found_with_bot: 'En este momento no hay conversaciones disponibles.\nPor favor, mantente atento(a) para futuras interacciones.',
    no_chats_found_without_bot: '¿Quieres hablar con tus contactos? Configura tu bot y conéctalo a tu sitio web para poder responder consultas al instante.',
    review_filter: 'Lo siento, no encontramos resultados para tu búsqueda. Por favor, asegúrate de que el nombre ingresado sea el correcto o intenta buscar por canal.',
    general_table: {
      name: 'Nombre',
      channel: 'Canal',
      actions: 'Acciones',
      state: 'Estado',
      last_message: 'Último Contacto',
      assigned_to: 'Asignado a',
      condition: 'Condición',
      intervened_by: 'Intervenido por',
      view_chat: 'Ver chat',
      two_hr_ago: 'Hace 2 horas',
    },
    contact_details: {
      contact_details: 'Detalles del contacto',
      general_info: 'Información general',
      browser_information: 'Información de navegación',
      first_contact: 'Primer contacto',
      site: 'Sitio',
      source: 'URL',
      medium: 'Medio',
      device_information: 'Informacion de dispositivo',
      source_device: 'Origen',
      ip_address: 'Dirección de IP',
      operating_system: 'Sistema operativo',
      browser: 'Navegador',
    },
    chat_preview: {
      chat_title: 'Chat',
      chat: 'Conversar',
      enter_chat_msg: 'Estás por ingresar al chat.',
      inactive_robot_msg: 'El robot quedará inactivo para este cliente, pide los datos que falten.',
      dont_show_again: 'No volver a mostrar este diálogo.',
      cancel: 'Cancelar',
      continue: 'Continuar',
      enter_chat: 'Ingresar al chat',
      is_chatting_with_contact: ' está conversando con el contacto.',
    },
    intervention_error: {
      chat_already_taken_error: 'Conversación no disponible',
      chat_already_taken_info: 'Esta conversación ya fue intervenida por un operador, por favor selecciona otra.',
    },
    warning_message: {
      chat_intervened: ' Este chat se ha intervenido desde el sitio',
      chat_disclaimer: ', si se ingresa al chat, no se silenciará a ningún participante.',
    },
    all_tags: 'Todas las etiquetas',
  },
  my_chats_section: {
    my_chats: 'Mis chats',
    active_chats: 'Chats activos',
    without_operators: 'Sin operador',
    end_chat: 'Finalizar chat',
    close_chat_question: '¿Seguro quieres cerrar este chat?',
    close_warning:
      'Si lo cierras sin haber obtenido mail o teléfono, el contacto no aparecerá en la lista de clientes.',
    unread_msg: 'Mensajes sin leer',
    transfer_chat: 'Transferir chat',
    transfer_to: 'Transferir a',
    write_reply: 'Escribe una respuesta',
    choose_agent: 'Elige un operador en línea',
    when_transfer: 'Al transferir, no podrás seguir chateando con el contacto.',
    you_transferred_to: 'Le transferiste el chat a',
    undo: 'Deshacer',
    no_operators: 'Sin operadores en línea',
    to_transfer_you_need: 'Para transferir un chat necesitas otro operador conectado.',
    understood: 'Entendido',
    interacctions_with_conversation: {
      you_joined_chat: 'Te uniste al chat',
      joined_chat: 'se unió al chat',
      leave_chat: 'finalizó el chat',
      transferred_to_you: 'te transfirió el chat',
      transferred_from_to: 'le transfirió el chat a',
      finished_conversation: 'no se encuentra conectado, este chat lo podrás seguir viendo desde Historial.',
      end_chat: 'Chat finalizado el',
      last_message: 'Último mensaje el',
    },
    chat_multimedia: {
      errors: {
        type_not_allowed: 'El tipo de archivo seleccionado no está permitido',
        max_file_length: 'El archivo seleccionado supera el peso permitido (máximo 60MB)',
        general_error: 'Ocurrió un error al subir el archivo, inténtalo más tarde',
        load_file_error: 'No podemos cargar el archivo',
      },
      type_extensions_title: '¿Qué tipo de archivos no puedo adjuntar?',
      type_extensions: 'https://cliengo.zendesk.com/hc/es/articles/360044633033',
      write_message: 'Escribe un comentario',
      file_format_error:
        'Algunos archivos no se pueden cargar debido al formato. Puedes encontrar más información en nuestro ',
      help_center: 'centro de ayuda.',
      cancel: 'Cancelar',
      add_more: 'Cargar más',
      remove_file: 'Eliminar archivo',
    },
    emoji_section: {
      smileys_people: 'Sonrisas y personas',
      animals_nature: 'Animales y naturaleza',
      travel_places: 'Lugares y viajes',
      activities: 'Actividades',
      objects: 'Objetos',
      symbols: 'Símbolos',
      recently_used: 'Recientes',
    },
    pick_emoji: 'Seleccionar emoji',
    button_send: 'Enviar',
  },
  history_section: {
    history: 'Historial',
    chat_history: 'Historial de chats',
    lead: 'Lead',
    no_lead: 'No lead',
    intervened: 'Intervenido',
    load_more: 'Cargar más',
    allSite: 'Todos los sitios',
    operatorFilterTitle: 'Intervino la Conversación',
    channelFilterTitle: 'Canal',
    conditionFilterTitle: 'Condición',
    infOnDurationChats: 'chats de los últimos 7 días.',
    form: {
      searchVisitor: 'Buscar por nombre',
      from: 'Desde',
      to: 'Hasta',
      cleanFilters: 'Limpiar filtros',
      applyFilters: 'Aplicar filtros',
    },
  },
  no_chats_available_history: {
    title: '¡Sin conversaciones por ahora!',
    legend: 'Aún no tienes ningún historial registrado en este momento, pero no te preocupes, una vez que hayas tenido interacciones o eventos, encontrarás aquí un registro detallado para revisar y hacer un seguimiento adecuado.',
    checklist: {
      label: 'Verás los chats de los contactos que:',
      points: ['Conversaron con el chatbot', 'Intervinieron los operadores', 'No llegaron a ser leads'],
    },
  },
  no_chats_available_history_search: {
    title: 'No hay resultados para esta búsqueda',
    with_name_filter: 'Lo siento, no encontramos resultados para tu búsqueda. Por favor, asegúrate de que el nombre ingresado sea el correcto o intenta buscar por canal.',
    with_other_filters: 'Lo siento, no encontramos resultados para tu búsqueda. Por favor, asegúrate de que el nombre ingresado o los filtros seleccionados sean los correctos.',
  },
  empty_state_section: {
    welcome: '¡Bienvenido a Live!',
    no_chats_available: '¡Sin conversaciones por ahora!',
    see_visitors_chatting: 'Aquí veras los visitantes que están conversando con el chatbot.',
    traffic_advise: 'Te aconsejamos aumentar el tráfico en tu sitio.',
    talk_to_visitor: 'No hay visitantes conectados en este momento. Cuando algún visitante inicie una conversación con el chatbot o un Agente, aparecerá en esta sección.',
    into_chats_no_operators: 'Ingresa a los chats están',
    no_operator_link: 'sin operador.',
    no_visitors_chatting_now: '¡Sin conversaciones por ahora!',
    no_visitors_logged_now: '¡Sin conversaciones por ahora!',
    no_chats_progress: 'No tienes chats en curso',
    want_talk_clients_question: '¿Quieres hablar con tus clientes?',
    see_visitors_chat: 'Aquí veras todos los visitantes que están conversando con el chatbot y los operadores.',
    want_talk_visitors: '¿Quieres hablar con tus visitantes?',
    get_chat_without_operator: 'Ingresa al chat de alguno de los visitantes que están',
    no_chats_to_see: {
      here_show_all_chats:
        'Aquí te mostraremos todas las conversaciones de tus visitantes con Cliengo. Verás los chats de los contactos que:',
      chat_with_bot: 'Conversaron con el chatbot',
      operators_intervened: 'Intervinieron los operadores',
      not_become_lead: 'No llegaron a ser leads',
    },
    no_chats_search: 'Revisa el filtro que elegiste o prueba con otra búsqueda.',
    connect_chatbot: 'Conecta tu chatbot',
    want_talk_clients: 'a un sitio web y responde las consultas de tus visitantes.',
    advise_increase_traffic: '💡 Te aconsejamos aumentar el tráfico en tu sitio.',
    no_message_error: {
      error_title: '¡Ups! Creo que hubo un error',
      error_content: 'No encontramos el chat que estabas buscando.',
    },
  },
  visitor_context: {
    tags: {
      title: 'Etiquetas',
      SHOULD_INTERVENE: 'Intervenir',
    },
    header: {
      title: 'Datos del visitante',
    },
    general_information: {
      title: 'Información general',
      empty_state_section: '',
      tooltip_content: 'Información general del cliente',
    },
    bot_response_module: {
      title: 'Respuesta del bot',
      empty_state_section: 'no ha seleccionado ninguna opción',
      tooltip_content: 'Opciones que seleccionaron mientras interactuaba con el bot',
    },
    navigation_information: {
      title: 'Información de navegación',
      empty_state_section: '',
      tooltip_content: 'Información de navegación',
      keynameSite: 'Sitio',
      keynameURL: 'URL',
    },
    browsing_history: {
      title: 'Historial de navegación',
      empty_state_section: 'Sólo podrá ver el historial de navegación en sitios web',
      tooltip_content: 'Sitios visitados hasta el momento',
    },
    visitor_inquiries: {
      title: 'Consultas del visitante',
      empty_state_section: 'Aún no detectamos ninguna consulta',
      tooltip_content: 'Extrajimos de la conversación algunas consultas que pueden ser de tu interés',
    },
  },
  channels: {
    WEB: 'Web',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    INSTAGRAM: 'Instagram',
    WSPLITE: 'WhatsApp Lite',
  },
  website_filter: {
    search: 'Buscar...',
  },
};
